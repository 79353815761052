body {
  background: #222;
}

a, a:hover, a:visited, a:active {
  color: #0e2933;
}

#content {
  position: fixed;
  display: table-cell;
  top: 0;
  width: 100%;
  height: 50vh;
  color: #fff;
  overflow: auto;
}

#content > div.wrap {
  padding: 20px;
  margin: 0;
  font-size: 1.8em;
  opacity: .8;
  color: #0e2933;
}

/* Style the video: 100% width and height to cover the entire window */
#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

.role {
  font-size: 60%;
  opacity: .8;
}

.role a,
.title a {
  padding: 10px;
  position: relative;
  left: -10px;
}

.title {
  padding: 10px 60px;
  background: url('avatar.png') 0 50%;
  background-size: 50px;
  background-repeat: no-repeat;
  top: 20px;
}

.linked-in-logo a {
  text-decoration: none;
  display: block;
  width: 48px;
  height: 48px;
}

.role a:hover {
  background: rgba(14, 41, 51, 0.8);
  color: #fff;
}

.linked-in-logo a:hover {
  opacity: .8;
}

.linked-in-logo svg {
  fill: #0e2933;
}

.linked-in-logo {
  position: absolute;
  right: 20px;
  top: 20px;
  opacity: .9;
}
